import React, { MutableRefObject } from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../components/TranslatedText";
import { Button, Typography } from "@lysaab/ui-2";
import { EditAccounrRiskParams } from "../../pageComponents/editAccountRiskConfirm/EditAccountRiskConfirm";

interface Props {
  values: MutableRefObject<EditAccounrRiskParams | undefined>;
  exitRoute: string;
}

export const Done: React.FunctionComponent<Props> = ({ values, exitRoute }) => {
  if (!values.current || typeof values.current.newRisk === "undefined") {
    return null;
  }

  const { newRisk, accountName } = values.current;

  return (
    <div className="update-account-page-done">
      <Typography type="h2">
        <TranslatedText id="updateAccountPage.done.header" />
      </Typography>

      <Typography>
        <TranslatedText
          id="updateAccountPage.done.allocation.text"
          values={{
            stocks: newRisk,
            bonds: 100 - parseInt(newRisk, 10),
            accountName,
          }}
        />
      </Typography>

      <Button
        component={Link}
        to={exitRoute}
        block
        label={<TranslatedText id="updateAccountPage.done.back" />}
      />
    </div>
  );
};

import {
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2/hooks/useValidation";
import { BaseValidator } from "@lysaab/ui-2/validators/BaseValidator";

export class AccountNameInvalidCharsValidator extends BaseValidator {
  static INVALID_CHARS = "<>'\"+\\%";
  static FORMATTED_INVALID_CHARS = `(${AccountNameInvalidCharsValidator.INVALID_CHARS.split(
    ""
  ).join(" ")})`;
  name = "AccountNameInvalidCharsValidator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (
        !value
          .split("")
          .every(
            (chr) =>
              AccountNameInvalidCharsValidator.INVALID_CHARS.indexOf(chr) === -1
          )
      ) {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
        return;
      }
      resolve({ status: VALIDATION_STATE.SUCCESS });
    });
  }
}

import React, { useEffect, useState } from "react";
import cx from "classnames";
import { FunctionComponent } from "react";
import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import "./ConfirmActionOverlay.scss";

export type ConfirmRef =
  | {
      setConfirm: (confirm: boolean) => void;
    }
  | undefined;

interface Props {
  confirmRef: React.MutableRefObject<ConfirmRef>;
  info?: string;
  negative?: boolean;
  confirmButtonText: string;
  cancelButtonText: string;
  errorMessage?: string | React.ReactNode;
  onConfirm?: () => void;
}

export const ConfirmActionOverlay: FunctionComponent<Props> = ({
  confirmRef,
  info,
  negative,
  confirmButtonText,
  cancelButtonText,
  errorMessage,
  onConfirm,
  children,
}) => {
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    if (confirmRef && !confirmRef.current?.setConfirm) {
      confirmRef.current = { setConfirm };
    }
  }, [confirmRef]);

  return (
    <div className="confirm-action-overlay">
      <div
        className={cx("remove-error", {
          active: errorMessage,
        })}
      >
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>{errorMessage}</div>
        </Snackbar>
      </div>
      <div
        className={cx("remove-confirm", {
          active: confirm,
        })}
      >
        {info && <p>{info}</p>}
        <div className="remove-confirm-buttons">
          <Button
            size="small"
            variant="secondary"
            onClick={() => {
              setConfirm(false);
            }}
            label={cancelButtonText}
          />
          <Button
            size="small"
            variant={negative ? "negative" : "primary"}
            onClick={() => {
              setConfirm(false);
              onConfirm?.();
            }}
            label={confirmButtonText}
          />
        </div>
      </div>
      <div
        className={cx("content", {
          "fade-content": confirm,
        })}
      >
        {children}
      </div>
    </div>
  );
};

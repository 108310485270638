/**
 * The offices has been grabbed from https://www.handelsbanken.se/sv/hitta-bankkontor
 * https://www.handelsbanken.se/rsoia/parg/bu/branches/v3/country/SE/sv
 */

export const handelbankenOffices = [
  {
    branchId: "6832",
    label: "Alingsås",
  },
  {
    branchId: "6154",
    label: "Alviks Torg",
  },
  {
    branchId: "6532",
    label: "Arboga",
  },
  {
    branchId: "6121",
    label: "Arenastaden",
  },
  {
    branchId: "6203",
    label: "Boden",
  },
  {
    branchId: "6664",
    label: "Bollebygd",
  },
  {
    branchId: "6407",
    label: "Bollnäs",
  },
  {
    branchId: "6455",
    label: "Borlänge",
  },
  {
    branchId: "6651",
    label: "Borås City",
  },
  {
    branchId: "6170",
    label: "Brommaplan",
  },
  {
    branchId: "6911",
    label: "Bålsta",
  },
  {
    branchId: "6730",
    label: "Båstad",
  },
  {
    branchId: "6151",
    label: "Djursholm",
  },
  {
    branchId: "6409",
    label: "Edsbyn",
  },
  {
    branchId: "6927",
    label: "Ekerö",
  },
  {
    branchId: "6503",
    label: "Enköping",
  },
  {
    branchId: "6692",
    label: "Eriksberg Lindholmen",
  },
  {
    branchId: "6602",
    label: "Eskilstuna",
  },
  {
    branchId: "6753",
    label: "Eslöv",
  },
  {
    branchId: "6533",
    label: "Fagersta",
  },
  {
    branchId: "6234",
    label: "Falkenberg",
  },
  {
    branchId: "6672",
    label: "Falköping",
  },
  {
    branchId: "6451",
    label: "Falun",
  },
  {
    branchId: "6174",
    label: "Farsta Centrum",
  },
  {
    branchId: "6567",
    label: "Fjugesta",
  },
  {
    branchId: "6720",
    label: "Gislaved",
  },
  {
    branchId: "6156",
    label: "Globen",
  },
  {
    branchId: "6204",
    label: "Gällivare",
  },
  {
    branchId: "6401",
    label: "Gävle City",
  },
  {
    branchId: "6226",
    label: "Göteborg Almedal",
  },
  {
    branchId: "6694",
    label: "Göteborg Avenyn",
  },
  {
    branchId: "6691",
    label: "Göteborg City",
  },
  {
    branchId: "6686",
    label: "Göteborg Frölunda",
  },
  {
    branchId: "6682",
    label: "Göteborg Första Långgatan",
  },
  {
    branchId: "6232",
    label: "Göteborg Sisjön",
  },
  {
    branchId: "6238",
    label: "Göteborg Torslanda",
  },
  {
    branchId: "6239",
    label: "Göteborg Volvo PVB",
  },
  {
    branchId: "6228",
    label: "Göteborg Örgryte",
  },
  {
    branchId: "6505",
    label: "Hallstavik",
  },
  {
    branchId: "6748",
    label: "Halmstad",
  },
  {
    branchId: "6116",
    label: "Hammarby Sjöstad",
  },
  {
    branchId: "6181",
    label: "Haninge Centrum",
  },
  {
    branchId: "6460",
    label: "Hedemora",
  },
  {
    branchId: "6751",
    label: "Helsingborg Stortorget",
  },
  {
    branchId: "6182",
    label: "Huddinge Centrum",
  },
  {
    branchId: "6415",
    label: "Hudiksvall",
  },
  {
    branchId: "6162",
    label: "Hägersten",
  },
  {
    branchId: "6301",
    label: "Härnösand",
  },
  {
    branchId: "6754",
    label: "Hässleholm",
  },
  {
    branchId: "6790",
    label: "Höganäs",
  },
  {
    branchId: "6726",
    label: "Höllviken",
  },
  {
    branchId: "6184",
    label: "Jakobsberg",
  },
  {
    branchId: "6641",
    label: "Jönköping",
  },
  {
    branchId: "6207",
    label: "Kalix",
  },
  {
    branchId: "6701",
    label: "Kalmar",
  },
  {
    branchId: "6742",
    label: "Karlshamn",
  },
  {
    branchId: "6581",
    label: "Karlskoga",
  },
  {
    branchId: "6741",
    label: "Karlskrona",
  },
  {
    branchId: "6801",
    label: "Karlstad",
  },
  {
    branchId: "6604",
    label: "Katrineholm",
  },
  {
    branchId: "6208",
    label: "Kiruna",
  },
  {
    branchId: "6921",
    label: "Kista",
  },
  {
    branchId: "6516",
    label: "Knivsta",
  },
  {
    branchId: "6308",
    label: "Kramfors",
  },
  {
    branchId: "6756",
    label: "Kristianstad Åhus",
  },
  {
    branchId: "6370",
    label: "Krokom",
  },
  {
    branchId: "6582",
    label: "Kumla",
  },
  {
    branchId: "6233",
    label: "Kungsbacka",
  },
  {
    branchId: "6903",
    label: "Kungsängen",
  },
  {
    branchId: "6856",
    label: "Kungälv",
  },
  {
    branchId: "6827",
    label: "Kävlinge",
  },
  {
    branchId: "6537",
    label: "Köping",
  },
  {
    branchId: "6757",
    label: "Landskrona",
  },
  {
    branchId: "6463",
    label: "Leksand",
  },
  {
    branchId: "6244",
    label: "Lerum",
  },
  {
    branchId: "6152",
    label: "Lidingö",
  },
  {
    branchId: "6655",
    label: "Lidköping",
  },
  {
    branchId: "6480",
    label: "Lima",
  },
  {
    branchId: "6573",
    label: "Lindesberg",
  },
  {
    branchId: "6600",
    label: "Linköping",
  },
  {
    branchId: "6806",
    label: "Ljungby",
  },
  {
    branchId: "6419",
    label: "Ljusdal",
  },
  {
    branchId: "6760",
    label: "Lomma",
  },
  {
    branchId: "6465",
    label: "Ludvika",
  },
  {
    branchId: "6201",
    label: "Luleå Storgatan",
  },
  {
    branchId: "6759",
    label: "Lund City",
  },
  {
    branchId: "6752",
    label: "Lund Ideon",
  },
  {
    branchId: "6259",
    label: "Lycksele",
  },
  {
    branchId: "6761",
    label: "Malmö City",
  },
  {
    branchId: "6833",
    label: "Malmö Fosie",
  },
  {
    branchId: "6773",
    label: "Malmö Fridhem",
  },
  {
    branchId: "6758",
    label: "Malmö Limhamn",
  },
  {
    branchId: "6770",
    label: "Malmö Stortorget",
  },
  {
    branchId: "6779",
    label: "Malmö Öster",
  },
  {
    branchId: "6673",
    label: "Mariestad",
  },
  {
    branchId: "6155",
    label: "Marievik",
  },
  {
    branchId: "6721",
    label: "Mjölby",
  },
  {
    branchId: "6467",
    label: "Mora",
  },
  {
    branchId: "6574",
    label: "Motala",
  },
  {
    branchId: "6690",
    label: "Mölndal",
  },
  {
    branchId: "6237",
    label: "Mölnlycke",
  },
  {
    branchId: "6920",
    label: "Mörby Centrum",
  },
  {
    branchId: "6165",
    label: "Nacka Forum",
  },
  {
    branchId: "6261",
    label: "Nordmaling",
  },
  {
    branchId: "6591",
    label: "Norrköping",
  },
  {
    branchId: "6507",
    label: "Norrtälje",
  },
  {
    branchId: "6262",
    label: "Norsjö - Malå",
  },
  {
    branchId: "6601",
    label: "Nyköping",
  },
  {
    branchId: "6196",
    label: "Nynäshamn",
  },
  {
    branchId: "6647",
    label: "Nässjö",
  },
  {
    branchId: "6708",
    label: "Oskarshamn",
  },
  {
    branchId: "6216",
    label: "Pajala",
  },
  {
    branchId: "6229",
    label: "Partille",
  },
  {
    branchId: "6210",
    label: "Piteå",
  },
  {
    branchId: "6539",
    label: "Sala Heby",
  },
  {
    branchId: "6194",
    label: "Saltsjö-Boo",
  },
  {
    branchId: "6423",
    label: "Sandviken",
  },
  {
    branchId: "6141",
    label: "Sigtuna",
  },
  {
    branchId: "6787",
    label: "Simrishamn",
  },
  {
    branchId: "6815",
    label: "Sjöbo",
  },
  {
    branchId: "6786",
    label: "Skanör",
  },
  {
    branchId: "6265",
    label: "Skellefteå",
  },
  {
    branchId: "6667",
    label: "Skövde",
  },
  {
    branchId: "6315",
    label: "Sollefteå",
  },
  {
    branchId: "6180",
    label: "Sollentuna Centrum",
  },
  {
    branchId: "6186",
    label: "Solna Centrum",
  },
  {
    branchId: "6816",
    label: "Staffanstorp",
  },
  {
    branchId: "6683",
    label: "Stenungsund",
  },
  {
    branchId: "6117",
    label: "Stockholm Arbetargatan",
  },
  {
    branchId: "6140",
    label: "Stockholm Gärdet",
  },
  {
    branchId: "6101",
    label: "Stockholm Götgatsbacken",
  },
  {
    branchId: "6129",
    label: "Stockholm Hornsberg",
  },
  {
    branchId: "6103",
    label: "Stockholm Humlegården",
  },
  {
    branchId: "6118",
    label: "Stockholm Högalid",
  },
  {
    branchId: "6112",
    label: "Stockholm Karlaplan",
  },
  {
    branchId: "6106",
    label: "Stockholm Kungsholmstorg",
  },
  {
    branchId: "6001",
    label: "Stockholm Kungsträdgården",
  },
  {
    branchId: "6123",
    label: "Stockholm Norrmalmstorg",
  },
  {
    branchId: "6133",
    label: "Stockholm Norrtullsgatan",
  },
  {
    branchId: "6109",
    label: "Stockholm S:t Eriksplan",
  },
  {
    branchId: "6126",
    label: "Stockholm Skanstull",
  },
  {
    branchId: "6124",
    label: "Stockholm Strandvägen",
  },
  {
    branchId: "6111",
    label: "Stockholm Stureplan",
  },
  {
    branchId: "6115",
    label: "Stockholm Vanadisplan",
  },
  {
    branchId: "6102",
    label: "Stockholm Vasagatan",
  },
  {
    branchId: "6110",
    label: "Stockholm Östermalmstorg",
  },
  {
    branchId: "6611",
    label: "Strängnäs",
  },
  {
    branchId: "6377",
    label: "Strömsund",
  },
  {
    branchId: "6153",
    label: "Sundbyberg",
  },
  {
    branchId: "6341",
    label: "Sundsvall",
  },
  {
    branchId: "6803",
    label: "Sunne",
  },
  {
    branchId: "6817",
    label: "Svedala",
  },
  {
    branchId: "6378",
    label: "Sveg",
  },
  {
    branchId: "6379",
    label: "Svenstavik",
  },
  {
    branchId: "6426",
    label: "Söderhamn",
  },
  {
    branchId: "6606",
    label: "Södertälje",
  },
  {
    branchId: "6510",
    label: "Tierp",
  },
  {
    branchId: "6648",
    label: "Tranås",
  },
  {
    branchId: "6821",
    label: "Trelleborg",
  },
  {
    branchId: "6845",
    label: "Trollhättan",
  },
  {
    branchId: "6901",
    label: "Tullinge",
  },
  {
    branchId: "6189",
    label: "Tyresö",
  },
  {
    branchId: "6179",
    label: "Täby Centrum",
  },
  {
    branchId: "6846",
    label: "Uddevalla",
  },
  {
    branchId: "6663",
    label: "Ulricehamn",
  },
  {
    branchId: "6251",
    label: "Umeå City",
  },
  {
    branchId: "6274",
    label: "Umeå Teg",
  },
  {
    branchId: "6160",
    label: "Upplands Väsby",
  },
  {
    branchId: "6514",
    label: "Uppsala Boländerna",
  },
  {
    branchId: "6501",
    label: "Uppsala City",
  },
  {
    branchId: "6513",
    label: "Uppsala Luthagen",
  },
  {
    branchId: "6515",
    label: "Uppsala Rosendal",
  },
  {
    branchId: "6915",
    label: "Vallentuna",
  },
  {
    branchId: "6473",
    label: "Vansbro",
  },
  {
    branchId: "6848",
    label: "Vara",
  },
  {
    branchId: "6231",
    label: "Varberg",
  },
  {
    branchId: "6650",
    label: "Vetlanda",
  },
  {
    branchId: "6268",
    label: "Vilhelmina",
  },
  {
    branchId: "6711",
    label: "Vimmerby",
  },
  {
    branchId: "6631",
    label: "Visby Öster",
  },
  {
    branchId: "6164",
    label: "Vällingby",
  },
  {
    branchId: "6831",
    label: "Vänersborg",
  },
  {
    branchId: "6270",
    label: "Vännäs",
  },
  {
    branchId: "6193",
    label: "Värmdö",
  },
  {
    branchId: "6700",
    label: "Värnamo",
  },
  {
    branchId: "6714",
    label: "Västervik",
  },
  {
    branchId: "6531",
    label: "Västerås City",
  },
  {
    branchId: "6543",
    label: "Västerås Köpingsvägen",
  },
  {
    branchId: "6546",
    label: "Västerås Öster Mälarstrand",
  },
  {
    branchId: "6879",
    label: "Växjö",
  },
  {
    branchId: "6765",
    label: "Ystad",
  },
  {
    branchId: "6195",
    label: "Åkersberga",
  },
  {
    branchId: "6851",
    label: "Åmål",
  },
  {
    branchId: "6348",
    label: "Ånge",
  },
  {
    branchId: "6381",
    label: "Åre",
  },
  {
    branchId: "6767",
    label: "Älmhult",
  },
  {
    branchId: "6247",
    label: "Älvsborg",
  },
  {
    branchId: "6212",
    label: "Älvsbyn",
  },
  {
    branchId: "6909",
    label: "Älvsjö",
  },
  {
    branchId: "6875",
    label: "Ängelholm",
  },
  {
    branchId: "6702",
    label: "Öland",
  },
  {
    branchId: "6578",
    label: "Örebro Drottningparken",
  },
  {
    branchId: "6580",
    label: "Örebro Ekersgatan",
  },
  {
    branchId: "6561",
    label: "Örebro Stortorget",
  },
  {
    branchId: "6318",
    label: "Örnsköldsvik",
  },
  {
    branchId: "6361",
    label: "Östersund",
  },
  {
    branchId: "6512",
    label: "Östhammar",
  },
];

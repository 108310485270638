import React, { FunctionComponent } from "react";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { PAIImportance } from "../../data/dataInvestments";
import { PAIQuestionInfoModal } from "./modals/PAIQuestionInfoModal";
import { useIsPerson } from "../../hooks/useIsPerson";

const messages = defineMessages({
  required: { id: "esg.pai.required" },
});

export const paiAltMessages = defineMessages<PAIImportance>({
  [PAIImportance.NOT_IMPORTANT]: {
    id: "esg.pai.alt.NOT_IMPORTANT",
  },
  [PAIImportance.IMPORTANT]: {
    id: "esg.pai.alt.IMPORTANT",
  },
});

interface Props {
  pai?: PAIImportance | Alternative<PAIImportance>;
  setPai: (pai: Alternative<PAIImportance>) => void;
}

export const PAIQuestion: FunctionComponent<Props> = ({ pai, setPai }) => {
  const intl = useIntl();
  const isPerson = useIsPerson();

  const alternatives = Object.values(PAIImportance).map((key) => ({
    text: intl.formatMessage(paiAltMessages[key]),
    value: key,
  }));

  const value =
    typeof pai === "string"
      ? {
          text: intl.formatMessage(paiAltMessages[pai]),
          value: pai,
        }
      : pai;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={
          <>
            {isPerson ? (
              <FormattedMessage id="esg.pai.header" />
            ) : (
              <FormattedMessage id="esg.pai.corp.header" />
            )}
            <br />
            <p>
              <PAIQuestionInfoModal />
            </p>
          </>
        }
        onChange={setPai}
        value={value}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
      />
    </Card>
  );
};

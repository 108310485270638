import React, { useCallback, useEffect } from "react";
import { Spinner, Story } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { Route, useHistory, useLocation } from "react-router";
import { Switch } from "../../components/route/Switch";
import { useStoryValues } from "../../hooks/useStoryValues";
import { PageStripped } from "../../pages/PageStripped";
import { ReviewAdviseWrapper } from "./pages/reviewAdvise/ReviewAdviseWrapper";
import { EditAllocationWrapper } from "./pages/editAllocationWrapper/EditAllocationWrapper";
import { RiskWarningWrapper } from "./pages/riskWarning/RiskWarningWrapper";
import { ConfirmationWrapper } from "./pages/confirmation/ConfirmationWrapper";
import { Eligibility } from "../../data/dataInvestments";
import { ConfirmEsgUpdateWrapper } from "./pages/confirmEsgUpdateWrapper/ConfirmEsgUpdateWrapper";
import { SuitabilityDownloadLazy } from "../../pageComponents/advise/SuitabilityDownload";
import * as H from "history";
import {
  ReviewAccountContextProvider,
  ReviewAccountState,
} from "./ReviewAccountContext";
import { useReviewHistory } from "./hooks/useReviewHistory";

export const REVIEW_ACCOUNT_URL = "/review-account";

export const BASE_ROUTES = {
  CONFIRM_ESG_UPDATE: `${REVIEW_ACCOUNT_URL}/confirm-esg-update`,
  REVIEW_HORIZON_ADVICE: `${REVIEW_ACCOUNT_URL}/review-horizon-advice`,
  CUSTOMIZE_RISK: `${REVIEW_ACCOUNT_URL}/customize-risk`,
  RISK_WARNING: `${REVIEW_ACCOUNT_URL}/risk-warning`,
  CONFIRM_UPDATE: `${REVIEW_ACCOUNT_URL}/confirm-update`,
};

const messages = defineMessages({
  header: {
    id: "reviewAccount.story.header",
  },
  ariaProgressLabel: {
    id: "reviewAccount.story.ariaProgressLabel",
  },
});

export interface ReviewAccountHistoryState<T = H.History.LocationState> {
  returnUrl: string;
  returnState?: ReviewAccountReturnState<T>;
  eligibility: Eligibility;
  preventDirectAccess?: boolean;
}

export type ReviewAccountReturnState<T = H.History.LocationState> = {
  reviewAccountState: ReviewAccountState;
} & T;

/**
 * This component is used to set the risk and AccountUpdateAction for the account
 */
export const ReviewAccountStoryInstance: React.VFC = () => {
  const location = useLocation<ReviewAccountHistoryState | undefined>(); //
  const intl = useIntl();
  const history = useHistory();
  // useReviewHistory hook handles populating ReviewAccountContext and manages access and more
  const { isLoading, eligibility, navigateToOrigin, navigateInStory } =
    useReviewHistory();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  useEffect(() => {
    // Preload the suitability PDF component early since it may reload the page which would result in loss of state
    SuitabilityDownloadLazy.preload();
  }, []);

  const navigateBackInStory = useCallback(() => {
    if (currentIndex === 0) {
      navigateToOrigin();
    } else {
      history.goBack();
    }
  }, [currentIndex, history, navigateToOrigin]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={true}
        showClose={true}
        transitionKey={currentIndex.toString()}
        onExit={() => navigateToOrigin()}
        onBack={navigateBackInStory}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route path={ROUTES.CONFIRM_ESG_UPDATE}>
            <ConfirmEsgUpdateWrapper
              next={() => navigateInStory(ROUTES.REVIEW_HORIZON_ADVICE)}
            />
          </Route>
          <Route path={ROUTES.REVIEW_HORIZON_ADVICE}>
            <ReviewAdviseWrapper
              next={() => navigateInStory(ROUTES.CONFIRM_UPDATE)}
              navigateToEditAllocation={() =>
                navigateInStory(ROUTES.CUSTOMIZE_RISK)
              }
              navigateToFees={() =>
                navigateInStory(ROUTES.CUSTOMIZE_RISK, "?fees")
              }
              eligibility={eligibility}
            />
          </Route>
          <Route path={ROUTES.CUSTOMIZE_RISK}>
            <EditAllocationWrapper
              next={() => navigateInStory(ROUTES.CONFIRM_UPDATE)}
              nextRiskWarning={() => navigateInStory(ROUTES.RISK_WARNING)}
            />
          </Route>
          <Route path={ROUTES.RISK_WARNING}>
            <RiskWarningWrapper
              navigateToConfirm={() => navigateInStory(ROUTES.CONFIRM_UPDATE)}
              navigateToOrigin={navigateToOrigin}
            />
          </Route>
          <Route path={ROUTES.CONFIRM_UPDATE}>
            <ConfirmationWrapper next={navigateToOrigin} />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
};

export const ReviewAccountStory: React.FC = () => {
  return (
    <ReviewAccountContextProvider>
      <ReviewAccountStoryInstance />
    </ReviewAccountContextProvider>
  );
};

import React, { ReactNode } from "react";
import { useIsReadOnly } from "../../hooks/useIsReadOnly";

interface Props {
  children: ReactNode;
}

export const HideIfReadOnly: React.FunctionComponent<Props> = ({
  children,
}) => {
  const isReadOnly = useIsReadOnly();

  if (isReadOnly) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

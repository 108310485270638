import { Button, Card, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { UserContext } from "../../../context/UserContext";
import {
  isInvestmentAccount,
  CompoundAccount,
} from "../../../data/dataAccounts";
import { LegalEntityType } from "../../../data/dataLogin";
import { dataWithdrawals } from "../../../data/dataWithdrawals";
import { useAccounts } from "../../../hooks/useAccounts";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { usePendingDeposits } from "../../../hooks/usePendingDeposits";
import { MESSAGES_PAGE_URL } from "../../messages/MessagesPage";
import { AddKlarnaAccountLocationState } from "../../withdrawal/addAccountKlarna/AddAccountKlarnaStory";
import { BASE_ROUTES } from "../CloseLysaCustomerStory";
import { dataSavingsAccountInterest } from "../../../data/dataSavingsAccountInterest";

interface Props {
  next: () => void;
  addWithdrawalAccountUrl?: string;
}

export const Intro = ({ next, addWithdrawalAccountUrl }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const userContext = useContext(UserContext);
  const loadPendingDeposits = usePendingDeposits();
  const [hasPendingDeposit, setHasPendingDeposit] = useState(true);
  const [meansOnAccounts, setMeansOnAccounts] = useState<number>(0);
  const [hasExternalAccount, setHasExternalAccount] = useState(false);
  const [hasAccruedInterest, setHasAccruedInterest] = useState(true);
  const { accounts } = useAccounts();

  useEffect(() => {
    if (
      typeof loadPendingDeposits === "undefined" ||
      typeof accounts === "undefined"
    ) {
      return;
    }
    Promise.all([
      dataWithdrawals.getExternalAccounts(),
      loadPendingDeposits(),
      accounts.savingsAccounts.length > 0
        ? dataSavingsAccountInterest.getMultipleAccruedInterest([
            ...accounts.savingsAccounts.map(
              (savingsAccount) => savingsAccount.accountId
            ),
          ])
        : [],
    ])
      .then(([externalAccounts, pendingDeposits, accruedInterestResponse]) => {
        const allAccounts = [
          ...accounts.investmentAccounts,
          ...accounts.savingsAccounts,
        ];
        const hasAccruedInterest = accruedInterestResponse.some(
          (account) => account.accruedInterest > 0
        );
        const meansOnAccounts = allAccounts.reduce(
          (accumulator: number, account: CompoundAccount) =>
            accumulator + moneyOnAccount(account),
          0
        );
        const hasPendingDeposit = allAccounts.some((account) => {
          return pendingDeposits.some(
            (deposit) => deposit.accountId === account.accountId
          );
        });
        setHasAccruedInterest(hasAccruedInterest);
        setHasPendingDeposit(hasPendingDeposit);
        setMeansOnAccounts(meansOnAccounts);
        setHasExternalAccount(externalAccounts.length > 0);
      })
      .finally(() => setIsLoading(false));
  }, [accounts, loadPendingDeposits]);

  if (userContext.state.legalEntityType === LegalEntityType.CORPORATION) {
    return (
      <React.Fragment>
        <h2>
          <TranslatedText id="closeLysaCustomerAccountStory.intro.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="closeLysaCustomerAccountStory.intro.corporateError" />
        </Snackbar>
        <Button
          block
          component={Link}
          to={getNavLink(MESSAGES_PAGE_URL)}
          label={
            <FormattedMessage id="closeLysaCustomerAccountStory.intro.button.messages" />
          }
        />
      </React.Fragment>
    );
  }

  const hasMeansOnAccountsButNoExternalAccount =
    (meansOnAccounts > 0 || hasAccruedInterest) && !hasExternalAccount;
  const isCloseCustomerDisallowed =
    hasPendingDeposit || hasMeansOnAccountsButNoExternalAccount;
  return (
    <React.Fragment>
      <h2>
        <TranslatedText id="closeLysaCustomerAccountStory.intro.header" />
      </h2>
      <Card>
        {isLoading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <p>
              <TranslatedText id="closeLysaCustomerAccountStory.intro.ingress" />
            </p>
            <p>
              <TranslatedText id="closeLysaCustomerAccountStory.intro.description" />
            </p>
            {isCloseCustomerDisallowed && (
              <Snackbar type={SNACKBAR_TYPES.WARNING}>
                {hasPendingDeposit && (
                  <p>
                    <TranslatedText id="closeLysaCustomerAccountStory.intro.hasPendingDepositWarning" />
                  </p>
                )}
                {addWithdrawalAccountUrl &&
                  hasMeansOnAccountsButNoExternalAccount && (
                    <React.Fragment>
                      <p>
                        <TranslatedText id="closeLysaCustomerAccountStory.intro.addExternalAccountText" />
                      </p>
                      <Link<AddKlarnaAccountLocationState>
                        to={{
                          pathname: addWithdrawalAccountUrl,
                          state: {
                            returnUrl: getNavLink(BASE_ROUTES.INTRO),
                          },
                        }}
                        className="lysa-link"
                      >
                        <TranslatedText id="closeLysaCustomerAccountStory.intro.addExternalAccount" />
                      </Link>
                    </React.Fragment>
                  )}
              </Snackbar>
            )}
          </React.Fragment>
        )}
      </Card>
      {!isCloseCustomerDisallowed && (
        <Button
          block
          onClick={next}
          label={
            <TranslatedText id="closeLysaCustomerAccountStory.intro.next" />
          }
        />
      )}
    </React.Fragment>
  );
};

export const moneyOnAccount = (account: CompoundAccount) => {
  if (isInvestmentAccount(account)) {
    return account.worth + account.uninvestedMoney;
  }
  return account.totalBalance;
};

import { Card, Icon, Button, Typography } from "@lysaab/ui-2";
import React, { MutableRefObject } from "react";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { TranslatedText } from "../../components/TranslatedText";
import { getNavLink } from "../../hooks/useCountryUrls";
import { EditAccounrRiskParams } from "../../pageComponents/editAccountRiskConfirm/EditAccountRiskConfirm";
import { ROUTES } from "./UpdateAccountPage";

interface Props {
  values: MutableRefObject<EditAccounrRiskParams | undefined>;
  exitRoute: string;
}

export const Warning: React.FunctionComponent<Props> = ({
  values,
  exitRoute,
}) => {
  const history = useHistory();
  const intl = useIntl();

  if (!values.current || typeof values.current.newRisk === "undefined") {
    return null;
  }

  const { advisedRisk, newRisk } = values.current;

  return (
    <div className="update-account-page-warning">
      <Typography type="h2">
        <TranslatedText id="updateAccountPage.warning.header" />
      </Typography>

      <Card>
        <Icon.Error size={48} color="#FDDE4A" />
        <p>
          <TranslatedText
            id="updateAccountPage.warning.text"
            description="update account page - warning text"
            values={{
              stocks: intl.formatNumber(Number(newRisk) / 100, {
                style: "percent",
              }),
              bonds: intl.formatNumber((100 - Number(newRisk)) / 100, {
                style: "percent",
              }),
              stocksAdvice: intl.formatNumber(Number(advisedRisk) / 100, {
                style: "percent",
              }),
              bondsAdvice: intl.formatNumber(
                (100 - Number(advisedRisk)) / 100,
                { style: "percent" }
              ),
            }}
          />
        </p>
      </Card>
      <Button
        component={Link}
        to={getNavLink(ROUTES.SUMMARY)}
        block
        label={<TranslatedText id="updateAccountPage.warning.next" />}
      />
      <Button
        variant="secondary"
        block
        onClick={() => {
          if (!values.current) {
            return;
          }

          values.current = {
            ...values.current,
            takenRisk: advisedRisk,
          };

          history.push(getNavLink(ROUTES.SUMMARY));
        }}
        label={<TranslatedText id="updateAccountPage.warning.nextLysa" />}
      />
      <Button
        variant="secondary"
        component={Link}
        to={exitRoute}
        block
        label={<TranslatedText id="updateAccountPage.warning.back" />}
      />
    </div>
  );
};

import {
  BaseValidator,
  VALIDATION_STATE,
  ValidationResponse,
} from "@lysaab/ui-2";
import { IntlShape } from "react-intl";
import { SavingsHorizonLength } from "../../data/dataInvestments";

export class RiskSavingsHorizonValidator extends BaseValidator {
  protected name = "RiskSavingsHorizonValidator";

  constructor(
    intl: IntlShape,
    private savingsHorizon: SavingsHorizonLength | undefined
  ) {
    super(intl.formatMessage({ id: "risk-savings-horizon--validator.error" }));
  }

  validate(value: unknown): Promise<ValidationResponse> {
    return new Promise((resolve) => {
      if (
        typeof value === "number" &&
        this.savingsHorizon === SavingsHorizonLength.SHORT &&
        value > 50
      ) {
        resolve({ status: VALIDATION_STATE.FAILED, message: this.error });
      }

      resolve({ status: VALIDATION_STATE.SUCCESS });
    });
  }
}

import { API, cache, encode } from "@lysaab/ui-2";
import { CompoundAccountId, InvestmentAccountId } from "./dataAccounts";

export interface InitiateSwishSuccessResponse {
  transactionId: string;
  token: string;
  qrCode?: string; //Remove this while swish has an error
}

export interface InitiateSwishErrorResponse {
  error: string;
}

export type InitiateSwishPaymentResponse =
  | InitiateSwishSuccessResponse
  | InitiateSwishErrorResponse;

export interface SwishPaymentRequest {
  accountId: CompoundAccountId;
  amount: number;
}

export enum SwishStatus {
  CREATED = "CREATED",
  PAID = "PAID",
  DECLINED = "DECLINED",
  ERROR = "ERROR",
}

interface SwishPaymentStatusErrorResponse {
  status: SwishStatus.ERROR;
  swishError: string;
}

interface SwishPaymentStatusGeneralResponse {
  status: Exclude<SwishStatus, SwishStatus.ERROR>;
}

export type SwishPaymentStatusResponse = (
  | SwishPaymentStatusGeneralResponse
  | SwishPaymentStatusErrorResponse
) & {
  payload: {
    amount: number;
    accountId: InvestmentAccountId;
    phone: string;
  };
};

export const dataSwish = {
  initiate: (body: SwishPaymentRequest) => {
    return API.post<InitiateSwishPaymentResponse>(
      `/swish/payment/initiate`,
      body
    );
  },
  pollStatus: (transactionId: string) => {
    return API.get<SwishPaymentStatusResponse>(
      encode`/swish/payment/status/${transactionId}`,
      true
    ).then((resp) => {
      if (resp.status === SwishStatus.PAID) {
        cache.clear();
      }
      return resp;
    });
  },
};

export function isInitiateSwishSuccessResponse(
  response: InitiateSwishPaymentResponse
): response is InitiateSwishSuccessResponse {
  return (
    typeof (response as InitiateSwishSuccessResponse).transactionId !==
    "undefined"
  );
}

import { Card, Form, Select } from "@lysaab/ui-2";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  Language,
  LocalizationContext,
} from "../../../context/LocalizationContext";
import { dataLegalEntity } from "../../../data/dataLegalEntity";
import { UserContext, setUserState } from "../../../context/UserContext";
import { onUserBootstrap } from "../../../data/dataLogin";

export const LanguageSelection = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const userContext = useContext(UserContext);

  const alternatives = Object.entries(Language).map((name) => {
    const language =
      intl.formatDisplayName(name[1], { type: "language" }) || name[0];
    return {
      text:
        language.charAt(0).toLocaleUpperCase() +
        language.slice(1).toLocaleLowerCase(),
      value: name[1],
    };
  });

  return (
    <section>
      <h2>
        <TranslatedText id="settings.language-selection.header" />
      </h2>
      <Card>
        <Form>
          <Select
            alternatives={alternatives}
            label="Language"
            placeholder="Select language"
            value={alternatives.find(
              (alt) => localizationContext.state.language === alt.value
            )}
            onChange={(newVal) => {
              localizationContext.setState({ language: newVal.value });
              dataLegalEntity
                .postProfileSettings({ language: newVal.value })
                .then(() => {
                  onUserBootstrap().then(([bootstrap, legalEntity]) => {
                    setUserState(bootstrap, legalEntity, userContext.setState);
                  });
                });
            }}
          />
        </Form>
      </Card>
    </section>
  );
};

import { useContext } from "react";
import { SavingsAccountId } from "../../../data/dataAccounts";
import { generatePath, Link } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { Card } from "@lysaab/ui-2/components/cards/Card";
import { useIntl } from "react-intl";
import { PerformanceContext } from "../../../context/PerformanceContext";
import { Amount } from "../../../components/amount/Amount";
import { FormattedStyledPercentage } from "../../../components/formattedPercentage/FormattedStyledPercentage";
import { TranslatedText } from "../../../components/TranslatedText";
import { SAVINGS_ACCOUNT_PAGE_URL } from "../../account/savingsAccountPage/SavingsAccountPage";
import { useSavingsAccount } from "../../../hooks/useSavingsAccount";
import { EffectiveInterestRateWithAccountId } from "../../../data/dataSavingsAccount";
import { intervalMessages } from "../IntervalMessages";
import { useAccountPerformance } from "../hooks/useAccountPerformance";

import "./OverviewSavingsAccountBase.scss";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../data/dataCustomerTracking";
import { motion } from "framer-motion";
import { AccountName } from "../../../components/accountName/AccountName";
import { useShowCurrency } from "../../../context/LayoutContext";
import { Typography } from "@lysaab/ui-2";
import { ExternalAccruedInterestResponse } from "../../../data/dataSavingsAccountInterest";

interface Props {
  accountId: SavingsAccountId;
  interestRate?: EffectiveInterestRateWithAccountId;
  accruedInterest?: ExternalAccruedInterestResponse;
}

export const OverviewSavingsAccount = ({
  accountId,
  interestRate,
  accruedInterest,
}: Props) => {
  const { account } = useSavingsAccount(accountId);
  const { interval } = useContext(PerformanceContext);
  const intl = useIntl();
  const accountPerformance = useAccountPerformance(accountId);
  const { showCurrency } = useShowCurrency();

  if (typeof account === "undefined") {
    return null;
  }

  return (
    <motion.div
      layout
      layoutId={account.accountId}
      className="overview-savings-account"
      transition={{
        type: "spring",
        damping: 24,
        stiffness: 200,
      }}
      animate={{ zIndex: 1 }}
    >
      <Link
        className="overview-page-account-link"
        to={getNavLink(
          generatePath(SAVINGS_ACCOUNT_PAGE_URL, {
            accountId: account.accountId,
          })
        )}
        onClick={() =>
          dataCustomerTrackingService.postEvent({
            domain: FeatureDomain.SAVINGS_ACCOUNT,
            eventName: "clickedAccountCard",
            subDomain: SubDomain.OVERVIEW,
            payload: {
              accountType: "Savings Account",
            },
          })
        }
      >
        <Card className="card--padding">
          <div className="flex">
            <AccountName
              account={account}
              showAmount={false}
              showRisk={false}
              showSavingsAccountIcon={true}
            />
            <div className="overview-account-button">
              <div className="button-primary button-small lysa-ui-button">
                <Typography type="label">
                  <TranslatedText id="overview.savingsAccount.showMore" />
                </Typography>
              </div>
            </div>
          </div>

          <span className="overview-savings-account__interest">
            <TranslatedText
              id="overview.savingsAccount.interest"
              values={{
                interest:
                  typeof interestRate === "undefined"
                    ? "-"
                    : intl.formatNumber(
                        Math.floor(interestRate.interestRate * 100_00) / 100_00,
                        {
                          style: "percent",
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }
                      ),
              }}
            />
          </span>

          <div className="overview-account-type-and-value">
            <span>
              <TranslatedText id="overview.savingsAccount.accountType" />
            </span>

            <div className="overview-account-value">
              <Amount
                amount={Math.floor(
                  account.totalBalance +
                    (accruedInterest
                      ? accruedInterest.accruedInterest - accruedInterest.fee
                      : 0)
                )}
              />
            </div>
          </div>
          <div className="overview-account-change-and-profits see-no-evil-hide">
            <div className="overview-account-dates">
              <TranslatedText {...intervalMessages[interval]} />
            </div>
            {showCurrency ? (
              <div className="overview-account-profits">
                {typeof accountPerformance !== "undefined" && (
                  <Amount
                    amount={Math.floor(accountPerformance.earnings)}
                    showSign={true}
                  />
                )}
              </div>
            ) : (
              <div className="overview-account-change">
                {typeof accountPerformance !== "undefined" && (
                  <FormattedStyledPercentage
                    value={accountPerformance.change}
                    decimals={1}
                    showSign={true}
                  />
                )}
              </div>
            )}
          </div>
        </Card>
      </Link>
    </motion.div>
  );
};

import { AccountInfo, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React, { VoidFunctionComponent } from "react";
import { TranslatedText } from "../../../../components/TranslatedText";

interface Props {
  accountInformation: AccountInfo;
  children?: (accountInformation: AccountInfo) => React.ReactNode;
}

const unsupportedBanks = [
  "Avanza Bank",
  "BlueStep Finans",
  "Ekobanken",
  "Erik Penser",
  "Landshypotek",
  "MedMera Bank",
  "Nordax Bank",
  "Nordnet Bank",
  "Resurs Bank",
  "Santander Consumer Bank",
  "SBAB",
];

export const UnsupportedBankInformation: VoidFunctionComponent<Props> = ({
  accountInformation,
  children,
}) => {
  if (!isBankSupported(accountInformation)) {
    if (typeof children === "undefined") {
      return (
        <div>
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.unsupported.autogiro.bank" />
          </Snackbar>
        </div>
      );
    }
    return <React.Fragment>{children(accountInformation)}</React.Fragment>;
  }
  return null;
};

export function isBankSupported(accountInformation: AccountInfo) {
  return !unsupportedBanks.includes(accountInformation.bank_name);
}

import React, { VoidFunctionComponent } from "react";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl, defineMessages, MessageDescriptor } from "react-intl";
import { NeedEarlierProbability } from "../../../data/dataInvestments";
import { LegalEntityType } from "../../../data/dataLogin";
import { useUser } from "../../../context/UserContext";

const messages = defineMessages({
  header: {
    id: "horizon.probability.header",
  },
  headerCorp: {
    id: "horizon.corp.probability.header",
  },
});

const needEarlierProbabilityQuestion: Record<
  LegalEntityType,
  MessageDescriptor
> = {
  [LegalEntityType.PERSON]: messages.header,
  [LegalEntityType.CORPORATION]: messages.headerCorp,
};

export const needEarlierProbabilityMessages =
  defineMessages<NeedEarlierProbability>({
    [NeedEarlierProbability.VERY_UNLIKELY]: {
      id: "horizon.probability.alt.veryunlikely",
    },
    [NeedEarlierProbability.UNLIKELY]: {
      id: "horizon.probability.alt.unlikely",
    },
    [NeedEarlierProbability.SOMEWHAT_UNLIKELY]: {
      id: "horizon.probability.alt.somewhatunlikely",
    },
    [NeedEarlierProbability.SOMEWHAT_LIKELY]: {
      id: "horizon.probability.alt.somewhatlikely",
    },
    [NeedEarlierProbability.LIKELY]: {
      id: "horizon.probability.alt.likely",
    },
    [NeedEarlierProbability.VERY_LIKELY]: {
      id: "horizon.probability.alt.verylikely",
    },
  });

interface Props {
  needEarlierProbability?:
    | NeedEarlierProbability
    | Alternative<NeedEarlierProbability>;
  setNeedEarlierProbability: (
    horizonProbability: Alternative<NeedEarlierProbability>
  ) => void;
}

export const NeedEarlierProbabilityInput: VoidFunctionComponent<Props> = ({
  needEarlierProbability,
  setNeedEarlierProbability,
}) => {
  const intl = useIntl();
  const legalEntityType = useUser().legalEntityType;

  const alternatives = Object.values(NeedEarlierProbability).map((key) => ({
    text: intl.formatMessage(needEarlierProbabilityMessages[key]),
    value: key,
  }));

  const value =
    typeof needEarlierProbability === "string"
      ? {
          text: intl.formatMessage(
            needEarlierProbabilityMessages[needEarlierProbability]
          ),
          value: needEarlierProbability,
        }
      : needEarlierProbability;

  if (typeof legalEntityType === "undefined") {
    return null;
  }

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={intl.formatMessage(
          needEarlierProbabilityQuestion[legalEntityType]
        )}
        onChange={setNeedEarlierProbability}
        value={value}
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "horizon.probability.required" })
          ),
        ]}
        data-test-id="horizon-probability"
      />
    </Card>
  );
};

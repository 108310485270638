import { Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { Modal } from "../modal/Modal";
import { motion } from "framer-motion";

import "./SeeNoEvilModal.scss";

interface Props {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onAccept: () => void;
}

export const SeeNoEvilModal: React.VFC<Props> = ({
  showModal,
  setShowModal,
  onAccept,
}) => {
  return (
    <Modal
      showModal={showModal}
      hideHeader
      width={500}
      closeOnOverlayClick
      onClose={() => setShowModal(false)}
    >
      <div className="see-no-evil-modal">
        <motion.div
          className="emoji"
          initial={{ rotate: 45, scale: 1.2 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{ type: "spring", damping: 1, stiffness: 50 }}
        >
          🙈
        </motion.div>
        <h3 className="header">
          <FormattedMessage id="seeNoEvilModal.header" />
        </h3>
        <p>
          <FormattedMessage id="seeNoEvilModal.description" />
        </p>
        <Button
          variant="primary"
          block
          onClick={() => {
            setShowModal(false);
            onAccept();
          }}
          label={<FormattedMessage id="seeNoEvilModal.okButtonText" />}
        />
      </div>
    </Modal>
  );
};

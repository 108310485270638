import React, { useContext, useState, VoidFunctionComponent } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { DocModal } from "@lysaab/ui-2";
import { LocalizationContext } from "../../context/LocalizationContext";
import { UserContext } from "../../context/UserContext";
import { InvestmentType } from "../../data/dataAccounts";
import "./ConfirmationTextSection.scss";
import {
  DocumentInformation,
  getLatestDocuments,
} from "../../data/dataDocuments";
import { LysaCountry } from "@lysaab/countries";
import { LegalEntityType } from "../../data/dataLogin";

interface MessageWithId {
  id: string;
}

const messages = defineMessages({
  modalHeader: {
    id: "confirmation.docs.header",
  },
  placeholder: {
    id: "confirmation.docs.placeholder",
  },
  modalAnnouncement: {
    id: "confirmation.docs.announcement",
  },
});

const investmentTypeMessages = defineMessages<
  InvestmentType,
  MessageWithId,
  Record<InvestmentType, MessageWithId>
>({
  [InvestmentType.BROAD]: {
    id: "confirmation.card.investment.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "confirmation.card.investment.SUSTAINABLE",
  },
});

const docList: Record<
  LysaCountry,
  (
    information: DocumentInformation[],
    legalEntityType: LegalEntityType
  ) => string[]
> = {
  [LysaCountry.DENMARK]: (information) =>
    getLatestDocuments(
      information,
      ...[
        ["legal/all/en/investment-guidelines.md"],
        ["legal/all/en/investment-account-agreement.md"],
        [
          "legal/all/en/general-terms-and-conditions-for-investment-accounts.md",
        ],
        ["legal/dk/en/depositor-information.md"],
        [
          "legal/all/en/information-about-lysa-v-2021-1.md",
          "legal/all/en/information-about-lysa-v-2021-2.md",
        ],
        [
          "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments.md",
        ],
      ]
    ),
  [LysaCountry.FINLAND]: (information) =>
    getLatestDocuments(
      information,
      ...[
        ["legal/all/en/investment-guidelines.md"],
        ["legal/all/en/investment-account-agreement.md"],
        [
          "legal/all/en/general-terms-and-conditions-for-investment-accounts.md",
        ],
        ["legal/fi/en/depositor-information.md"],
        [
          "legal/all/en/information-about-lysa-v-2021-1.md",
          "legal/all/en/information-about-lysa-v-2021-2.md",
        ],
        [
          "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments.md",
        ],
      ]
    ),
  [LysaCountry.GERMANY]: (information) =>
    getLatestDocuments(
      information,
      ...[
        ["legal/de/de/investment-guidelines.md"], // Anlagerichtlinien
        ["legal/de/de/investment-account-agreement.md"], // Depotvertrag
        ["legal/de/de/general-terms-and-conditions-for-investment-accounts.md"],
        ["legal/de/de/depositor-information.md"], // Grundlegende Informationen zur Einlagensicherung
        ["legal/de/de/information-about-lysa-v-2021-1.md"],
        [
          "legal/de/de/information-regarding-characteristics-and-risks-relating-to-financial-instruments.md",
        ],
      ]
    ),
  [LysaCountry.SWEDEN]: (information, legalEntityType) =>
    getLatestDocuments(
      information,
      ...(legalEntityType === LegalEntityType.PERSON
        ? [
            ["legal/se/sv/placeringsriktlinjer.md"],
            ["legal/se/sv/avtal-om-isk.md"],
            ["legal/se/sv/allmänna-villkor-isk.md"],
            ["legal/se/sv/account-terms.md"],
            ["legal/se/sv/depositor-information.md"],
            [
              "legal/se/sv/information-om-lysa-v-2021-1.md",
              "legal/se/sv/information-om-lysa-v-2021-2.md",
              "legal/se/sv/information-om-lysa-v-2022-1.md",
            ],
            ["legal/se/sv/förhandsinformation-om-investeringssparkonto.md"],
            [
              "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument.md",
            ],
          ]
        : [
            ["legal/se/sv/placeringsriktlinjer.md"],
            ["legal/se/sv/depåavtal-1.md"],
            ["legal/se/sv/account-terms.md"],
            ["legal/se/sv/depositor-information.md"],
            [
              "legal/se/sv/information-om-lysa-v-2021-1.md",
              "legal/se/sv/information-om-lysa-v-2021-2.md",
              "legal/se/sv/information-om-lysa-v-2022-1.md",
            ],
            [
              "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument.md",
            ],
          ])
    ),
  [LysaCountry.SPAIN]: (information) =>
    getLatestDocuments(
      information,
      ...[
        ["legal/all/en/investment-guidelines.md"],
        ["legal/es/en/investment-account-agreement.md"],
        ["legal/es/en/general-terms-and-conditions-for-investment-accounts.md"],
        ["legal/es/en/depositor-information.md"],
        ["legal/es/en/information-about-lysa-v-2021-1.md"],
        [
          "legal/es/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments.md",
        ],
      ]
    ),
};

const depositorInformation: Record<
  LysaCountry,
  (information: DocumentInformation[]) => string[]
> = {
  [LysaCountry.DENMARK]: (information) =>
    getLatestDocuments(information, ["legal/dk/en/depositor-information.md"]),
  [LysaCountry.FINLAND]: (information) =>
    getLatestDocuments(information, ["legal/fi/en/depositor-information.md"]),
  [LysaCountry.GERMANY]: (information) =>
    getLatestDocuments(information, ["legal/de/de/depositor-information.md"]),
  [LysaCountry.SWEDEN]: (information) =>
    getLatestDocuments(information, ["legal/se/sv/depositor-information.md"]),
  [LysaCountry.SPAIN]: (information) =>
    getLatestDocuments(information, ["legal/es/en/depositor-information.md"]),
};

interface Props {
  risk: number;
  accountName: string;
  investmentType: InvestmentType;
  documents: DocumentInformation[];
}

export const ConfirmationTextSection: VoidFunctionComponent<Props> = ({
  risk,
  accountName,
  investmentType,
  documents,
}) => {
  const localizationContext = useContext(LocalizationContext);
  const { country } = localizationContext.state;
  const [showDoc, setShowDoc] = useState<string>();
  const userContext = useContext(UserContext);
  const { legalEntityType } = userContext.state;
  const intl = useIntl();

  const varValues: Record<string, string> = {
    SIGNING_DATE: intl.formatDate(new Date()),
    CUSTOMER_NAME: userContext.state.name ?? "",
    ACCOUNT_NAME: accountName,
    STOCKS: risk ? intl.formatNumber(risk / 100, { style: "percent" }) : "",
    BONDS: risk
      ? intl.formatNumber((100 - risk) / 100, { style: "percent" })
      : "",
    INVESTMENT_TYPE: intl.formatMessage(investmentTypeMessages[investmentType]),
    CURRENCY: localizationContext.state.currency,
  };

  if (!country || !legalEntityType) {
    return null;
  }

  return (
    <React.Fragment>
      <DocModal
        header={intl.formatMessage(messages.modalHeader)}
        active={!!showDoc}
        onRequestClose={() => setShowDoc(undefined)}
        variableValues={varValues}
        docName={showDoc}
        selectPlaceholder={intl.formatMessage(messages.placeholder)}
        docList={docList[country](documents, legalEntityType)}
        modalAnnouncement={intl.formatMessage(messages.modalAnnouncement)}
      />
      <section className="confirmation-text-section">
        <div className="confirmation-text">
          <FormattedMessage id="confirmation.confirm" />
          <ul>
            <li>
              <FormattedMessage id="confirmation.confirm.1" />
            </li>
            <li>
              <FormattedMessage id="confirmation.confirm.2" />
            </li>
            <li>
              <FormattedMessage id="confirmation.confirm.3" />
            </li>
            <li>
              <FormattedMessage id="confirmation.confirm.4" />
            </li>
          </ul>
        </div>

        <button
          className="as-link"
          type="button"
          onClick={(ev) => {
            ev.preventDefault();
            setShowDoc(docList[country](documents, legalEntityType)[0]);
          }}
        >
          <FormattedMessage id="confirmation.confirm.link" />
        </button>
        <button
          className="as-link"
          type="button"
          onClick={(ev) => {
            ev.preventDefault();
            setShowDoc(depositorInformation[country](documents)[0]);
          }}
        >
          <FormattedMessage id="confirmation.confirm.guaranteelink" />
        </button>
      </section>
    </React.Fragment>
  );
};

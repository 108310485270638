import { VoidFunctionComponent } from "react";
import { preloadableImport } from "../../components/safeLazyImport/PreloadableImport";
import { SafeLazyImport } from "../../components/safeLazyImport/SafeLazyImport";
import {
  AccountQuestions,
  EsgResultResponse,
  RecursivePartial,
  Eligibility,
} from "../../data/dataInvestments";

export interface SuitabilityDownloadProps {
  render?(url: string, filename: string): React.ReactElement;
  esgQuestions?: EsgResultResponse["esgResult"];
  accountQuestions: AccountQuestions;
  advisedRisk: number;
  eligibility?: RecursivePartial<Eligibility>;
  isKf?: boolean;
}

export const SuitabilityDownloadLazy = preloadableImport(() =>
  import("./SuitabilityDownloadLazy").then((module) => ({
    default: module.SuitabilityDownloadLazy,
  }))
);

export const SuitabilityDownload: VoidFunctionComponent<
  SuitabilityDownloadProps
> = (props) => {
  return (
    <SafeLazyImport>
      <SuitabilityDownloadLazy {...props} />
    </SafeLazyImport>
  );
};

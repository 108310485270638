import { API, cache, encode } from "@lysaab/ui-2";
import {
  InvestmentAccount,
  GET_ACCOUNTS_URL,
  CompoundAccountId,
  SavingsAccount,
  InvestmentAccountId,
  SavingsAccountId,
  AccountType,
  CompoundAccount,
  isSavingsAccount,
  isInvestmentAccount,
} from "./dataAccounts";

export interface TransferStatus {
  isComplete?: boolean;
}

export interface TransferResponse {
  transactionId: string;
}

export enum TransferableGeneral {
  OK = "OK",
  LACKS_FUNDS = "LACKS_FUNDS",
  PENDING_WITHDRAWAL = "PENDING_WITHDRAWAL",
  PENDING_INTERNAL_TRANSFER = "PENDING_INTERNAL_TRANSFER",
}

export enum TransferableInvestment {
  PENDING_ORDER = "PENDING_ORDER",
}

export enum TransferableSavings {
  PENDING_CASH_TRANSFER = "PENDING_CASH_TRANSFER",
  PENDING_REBALANCING = "PENDING_REBALANCING",
}

export type MergedInternalTransferAccount =
  | MergedInternalTransferInvestmentAccount
  | (SavingsAccount & InternalTransferSavingsAccount);

export type MergedInternalTransferInvestmentAccount = InvestmentAccount &
  InternalTransferInvesmentAccount;

export interface InternalTransferInvesmentAccount {
  accountId: InvestmentAccountId;
  transferable: TransferableGeneral | TransferableInvestment;
  maxTransfer: number;
  maxWithdrawal: number;
}

export interface InternalTransferSavingsAccount {
  accountId: SavingsAccountId;
  transferable: TransferableGeneral | TransferableSavings;
  maxTransfer: number;
  maxWithdrawal: number;
}

type InternalTransferAccount =
  | InternalTransferInvesmentAccount
  | InternalTransferSavingsAccount;

export enum TransferType {
  AMOUNT = "AMOUNT",
  DRAIN = "DRAIN",
}

export interface AddTransferDrainArguments {
  toAccountId: CompoundAccountId;
  fromAccountId: InvestmentAccountId;
  requestId: number;
  transferType: TransferType.DRAIN;
}

export interface AddTransferAmountArguments {
  toAccountId: CompoundAccountId;
  fromAccountId: CompoundAccountId;
  requestId: number;
  amount: number;
  transferType: TransferType.AMOUNT;
}

export interface PendingInternalTransfer {
  transactionId: string;
  fromAccountId: CompoundAccountId;
  toAccountId: CompoundAccountId;
  amount: number | null;
  created: string;
}

export const dataTransfer = {
  addTransfer(request: AddTransferDrainArguments | AddTransferAmountArguments) {
    if (request.transferType === TransferType.DRAIN) {
      return API.post<TransferResponse>("/transfer/transfer", {
        toAccountId: request.toAccountId,
        fromAccountId: request.fromAccountId,
        requestId: request.requestId,
      }).then((response) => {
        cache.delete(GET_ACCOUNTS_URL);
        cache.delete("/transfer");
        cache.delete("/withdrawal");
        cache.delete("/transactions");
        return response;
      });
    } else {
      return API.post<TransferResponse>("/transfer/transfer", {
        toAccountId: request.toAccountId,
        fromAccountId: request.fromAccountId,
        requestId: request.requestId,
        amount: request.amount,
      }).then((response) => {
        cache.delete(GET_ACCOUNTS_URL);
        cache.delete("/transfer");
        cache.delete("/withdrawal");
        cache.delete("/transactions");
        return response;
      });
    }
  },
  pollStatus(transactionId: string) {
    return API.get<TransferStatus>(encode`/transfer/poll/${transactionId}`);
  },
  getInternalTransferAccounts() {
    return API.get<InternalTransferAccount[]>("/transfer/accounts");
  },
  getPendingInternalTransfers() {
    return API.get<PendingInternalTransfer[]>("/transfer/pending");
  },
};

export const isMergedInternalTransferInvestmentAccount = (
  account?: MergedInternalTransferAccount
): account is MergedInternalTransferInvestmentAccount => {
  if (typeof account === "undefined") {
    return false;
  }
  return "type" in account;
};

export const isDanicaKF = (account?: MergedInternalTransferAccount) => {
  if (typeof account === "undefined") {
    return false;
  }
  return "type" in account && account.type === AccountType.DANICA_KF;
};

export const getMaxTransferableAmount = (
  fromAccount: MergedInternalTransferAccount,
  toAccount: CompoundAccount
) => {
  if (
    (isSavingsAccount(toAccount) && isInvestmentAccount(fromAccount)) ||
    (isInvestmentAccount(toAccount) && isSavingsAccount(fromAccount))
  ) {
    return fromAccount.maxWithdrawal;
  } else {
    return fromAccount.maxTransfer;
  }
};

import React, { useState, useCallback, useEffect, useContext } from "react";
import { InvestmentAccount } from "../../../../data/dataAccounts";
import { useInView } from "react-intersection-observer";
import "./PositionsExample.scss";
import { Status, Retry } from "../../../../components/retry/Retry";
import { FundType, Fund, dataFunds } from "../../../../data/dataFunds";
import { Amount } from "../../../../components/amount/Amount";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Disclaimer } from "../../Disclaimer";
import {
  dataInvestments,
  GetAllocationResponse,
} from "../../../../data/dataInvestments";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { useAccountAllocation } from "../../../../hooks/useAccountAllocation";
import { PositionsExampleList } from "./PositionsExampleList";
import { CardList, Typography } from "@lysaab/ui-2";
import { CardBottom } from "../../CardBottom";
import { Modal } from "../../../../components/modal/Modal";

const messages = defineMessages({
  header: {
    id: "accountPage.positions.example.holdings",
  },
});

interface Props {
  account: InvestmentAccount | undefined;
}

function checkPositions(allocations?: GetAllocationResponse, funds?: Fund[]) {
  if (!allocations || !funds) {
    return {
      hasStocks: false,
      hasBonds: false,
      funds: [] as Fund[],
    };
  }

  return funds.reduce(
    (data, fund) => {
      const shareClass = fund.fundShareClasses.find(({ isin }) =>
        Object.keys(allocations).includes(isin)
      );
      if (shareClass) {
        data.hasStocks = data.hasStocks || fund.fundType === FundType.STOCKS;
        data.hasBonds = data.hasBonds || fund.fundType === FundType.BONDS;
        data.funds.push({ ...fund, isin: shareClass.isin });
      }
      return data;
    },
    {
      hasStocks: false,
      hasBonds: false,
      funds: [] as Fund[],
    }
  );
}

export const PositionsExample: React.FC<Props> = ({ account }) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [holdings, setHoldings] = useState<Fund[]>();
  const [allocations, setAllocations] = useState<GetAllocationResponse>();
  const { hasBonds, hasStocks, funds } = checkPositions(allocations, holdings);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [showModal, setShowModal] = useState(false);
  const accountAllocation = useAccountAllocation(account?.accountId);

  const load = useCallback(() => {
    if (!localizationContext.state.country || !account || !accountAllocation) {
      return;
    }

    Promise.all([
      dataInvestments
        .getAllocation(
          accountAllocation.takenRisk,
          accountAllocation.investmentType,
          localizationContext.state.country
        )
        .then(setAllocations),
      dataFunds.getHoldings().then(setHoldings),
    ])
      .then(() => setStatus(Status.SUCCESS))
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [account, accountAllocation, localizationContext.state.country]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);

    setTimeout(load, 600);
  }, [load]);

  useEffect(() => {
    if (!inView || !account || !localizationContext.state.country) {
      return;
    }
    load();
  }, [account, inView, load, localizationContext.state.country]);

  return (
    <section className="account-page-positions-example" ref={ref}>
      <Typography type="h2">{intl.formatMessage(messages.header)}</Typography>

      <Retry retry={retry} status={status}>
        {!!account?.uninvestedMoney && (
          <div className="cash-assets">
            <FormattedMessage id="accountPage.positions.example.cashAssets" />
            : <Amount amount={account.uninvestedMoney} />
          </div>
        )}
        {((account && funds && hasStocks) ||
          (account && funds && hasBonds)) && (
          <CardList className="list">
            <div className="account-page-padder">
              <div className="account-page-card-body">
                {account && funds && hasStocks && (
                  <>
                    <h4>
                      <FormattedMessage id="accountPage.positions.example.stocks" />
                    </h4>
                    <PositionsExampleList
                      funds={funds}
                      fundType={FundType.STOCKS}
                    />
                  </>
                )}

                {account && funds && hasBonds && (
                  <>
                    <h4>
                      <FormattedMessage id="accountPage.positions.example.bonds" />
                    </h4>
                    <PositionsExampleList
                      funds={funds}
                      fundType={FundType.BONDS}
                    />
                  </>
                )}
                <div className="fade" />
              </div>
              <CardBottom>
                <button className="as-link" onClick={() => setShowModal(true)}>
                  <FormattedMessage id="accountPage.positions.example.cta.show-holdings" />
                </button>
              </CardBottom>
            </div>
          </CardList>
        )}
      </Retry>

      <Disclaimer>
        <FormattedMessage id="accountPage.positions.example.disclaimer" />
      </Disclaimer>

      <Modal
        header={intl.formatMessage(messages.header)}
        showModal={showModal}
        onClose={() => setShowModal(false)}
        closeOnOverlayClick
      >
        <div className="modal-list">
          {account && funds && hasStocks && (
            <>
              <h4>
                <FormattedMessage id="accountPage.positions.example.stocks" />
              </h4>
              <PositionsExampleList funds={funds} fundType={FundType.STOCKS} />
            </>
          )}

          {account && funds && hasBonds && (
            <>
              <h4>
                <FormattedMessage id="accountPage.positions.example.bonds" />
              </h4>
              <PositionsExampleList funds={funds} fundType={FundType.BONDS} />
            </>
          )}
        </div>
      </Modal>
    </section>
  );
};

import React, { useCallback, useContext, useEffect, useReducer } from "react";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../data/dataCustomerTracking";
import { useIsSignedIn } from "../hooks/useIsSignedIn";
import { SEE_NO_EVIL, Store } from "../Store";

export interface LayoutState {
  showCookieConsent: boolean;
  hideNav: boolean;
  pageStripped: boolean;
  seeNoEvil: boolean;
  showCurrency: boolean;
  showBanner: boolean;
}

export interface LayoutContextProps {
  state: LayoutState;
  setState: (newState: Partial<LayoutState>) => void;
}

export const LayoutContext = React.createContext<LayoutContextProps>(
  {} as LayoutContextProps
);

export const withLayout =
  <P extends object>(
    Component: React.ComponentType<P>
  ): React.FC<Omit<P, keyof LayoutContextProps>> =>
  (props) =>
    (
      <LayoutContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </LayoutContext.Consumer>
    );

function stateReducer(state: LayoutState, newState: Partial<LayoutState>) {
  return { ...state, ...newState };
}

export const LayoutContextProvider: React.FC = ({ children }) => {
  const isSignedIn = useIsSignedIn();
  const [state, setState] = useReducer(stateReducer, {
    pageStripped: false,
    hideNav: false,
    showCookieConsent: false,
    seeNoEvil: Store.getValue(SEE_NO_EVIL),
    showCurrency: false,
    showBanner: true,
  });

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }
    Store.setValue(SEE_NO_EVIL, state.seeNoEvil);
    dataCustomerTrackingService.postEvent({
      eventName: state.seeNoEvil ? "seeNoEvilOn" : "seeNoEvilOff",
      domain: FeatureDomain.GENERAL,
      subDomain: SubDomain.OVERVIEW,
    });
  }, [state.seeNoEvil, isSignedIn]);

  return (
    <LayoutContext.Provider value={{ state, setState }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useShowCurrency = () => {
  const layout = useContext(LayoutContext);

  if (typeof layout === "undefined") {
    throw new Error("Missing Layout Context Provider");
  }

  const toggleCurrency = useCallback(() => {
    layout.setState({
      showCurrency: !layout.state.showCurrency,
    });
  }, [layout]);

  return {
    showCurrency: layout.state.showCurrency,
    toggleCurrency,
  };
};

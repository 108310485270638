import React, { useState, useCallback, useEffect, useContext } from "react";
import cx from "classnames";
import {
  InvestmentAccount,
  dataAccounts,
  normalizeAccountName,
} from "../../data/dataAccounts";
import {
  Card,
  Form,
  TextInput,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  LysaFormRef,
  RequiredValidator,
  MaxLengthValidator,
} from "@lysaab/ui-2";
import { AccountNameInvalidCharsValidator } from "../../components/validators/AccountNameInvalidCharsValidator";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { UserContext } from "../../context/UserContext";
import "./ChangeName.scss";
import { EventTracker } from "../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../data/dataCustomerTracking";
import { AccountNameAlreadyExistsValidator } from "../../components/validators/AccountNameAlreadyExistsValidator";

interface Props {
  account?: InvestmentAccount;
  onUpdate: () => void;
}

enum Status {
  DEFAULT = "DEFAULT",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

const messages = defineMessages({
  empty: {
    id: "accountPage.changeName.error.empty",
  },
  long: {
    id: "accountPage.changeName.error.long",
  },
  invalid: {
    id: "accountPage.changeName.error.invalid",
  },
  nameAlreadyExists: {
    id: "accountPage.changeName.error.nameAlreadyExists",
  },
  name: {
    id: "accountPage.changeName.name",
  },
});

export function ChangeName({ account, onUpdate }: Props) {
  const formRef = React.useRef<LysaFormRef>();
  const [name, setName] = useState<string>("");
  const [status, setStatus] = useState(Status.PENDING);
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const intl = useIntl();

  const updateText = useCallback(
    (value) => {
      setName(value || "");
    },
    [setName]
  );

  useEffect(() => {
    if (!account) {
      return;
    }
    setName(account.name);
  }, [account]);

  const save = useCallback(
    (event) => {
      event.preventDefault();
      if (!account) {
        return;
      }

      setStatus(Status.PENDING);

      if (normalizeAccountName(name) === account.name) {
        setStatus(Status.SUCCESS);
        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 3000);
        return;
      }

      if (formRef.current && formRef.current.isValid) {
        dataAccounts
          .updateAccountName(account.accountId, name)
          .then(() => {
            EventTracker.track({
              event: TrackerEvent.ACCOUNT_NAME_CHANGE,
              message: {
                name: name,
              },
            });
            setStatus(Status.SUCCESS);
            onUpdate();
            setTimeout(() => {
              setStatus(Status.DEFAULT);
            }, 5000);
          })
          .catch((err) => {
            console.warn(err);
            setStatus(Status.ERROR);
            setTimeout(() => {
              setStatus(Status.DEFAULT);
            }, 3000);
          });
      }
    },
    [formRef, name, account, onUpdate]
  );

  return (
    <section
      className={cx("account-page-change-name", {
        "has-error": status === Status.ERROR,
        "is-pending": status === Status.PENDING,
        "was-saved": status === Status.SUCCESS,
      })}
    >
      <h2>
        <FormattedMessage id="accountPage.changeName.header" />
      </h2>
      <Card>
        <Form lysaFormRef={formRef} onSubmit={save}>
          <TextInput
            value={name}
            label={intl.formatMessage(messages.name)}
            onChange={updateText}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.empty)),
              new AccountNameInvalidCharsValidator(
                intl.formatMessage(messages.invalid, {
                  invalidChars:
                    AccountNameInvalidCharsValidator.FORMATTED_INVALID_CHARS,
                })
              ),
              new MaxLengthValidator(50, intl.formatMessage(messages.long)),
              new AccountNameAlreadyExistsValidator(
                account?.name ?? "",
                intl.formatMessage(messages.nameAlreadyExists)
              ),
            ]}
            disabled={user.readOnly ? true : false}
          />
          <Button
            size="small"
            block
            type="submit"
            disabled={user.readOnly ? true : false}
            label={<FormattedMessage id="accountPage.changeName.save" />}
          />
        </Form>

        <div className="account-page-change-name-saved">
          <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
            <span>
              <b>
                <FormattedMessage id="thanks" />
              </b>{" "}
              <FormattedMessage id="accountPage.changeName.saved" />
            </span>
          </Snackbar>
        </div>

        <div className="account-page-change-name-error">
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <span>
              <b>
                <FormattedMessage id="ouch" />
              </b>{" "}
              <FormattedMessage id="retry" />
            </span>
          </Snackbar>
        </div>
      </Card>
    </section>
  );
}

import React, { FunctionComponent, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  id: string;
  description?: string;
  defaultMessage?: string;
  values?: {
    [key: string]: string | number | ReactNode;
  };
}

export const defaultFormatters = {
  bold: (...parts: string[]) => <b>{parts}</b>,
  italic: (...parts: string[]) => <i>{parts}</i>,
  underline: (...parts: string[]) => <u>{parts}</u>,
  break: () => (
    <>
      <br />
      <br />
    </>
  ),
};

export const TranslatedText: FunctionComponent<Props> = ({
  values,
  children,
  ...args
}) => (
  <FormattedMessage
    {...args}
    values={{
      ...defaultFormatters,
      ...values,
    }}
  />
);

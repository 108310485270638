import React, { useEffect, useState, useCallback, useRef } from "react";
import cx from "classnames";
import { CardList, Typography } from "@lysaab/ui-2";
import { InvestmentAccount, CompoundAccount } from "../../data/dataAccounts";
import {
  CashTransaction,
  CashTransactionType,
  InternalTransfer,
  dataTransactions,
  instanceOfCashTransaction,
  instanceOfInternalTransfer,
} from "../../data/dataTransactions";
import { dataUser } from "../../data/dataUser";
import { Retry, Status } from "../../components/retry/Retry";
import { TransactionEntry } from "./TransactionEntry";
import { CardBottom } from "./CardBottom";
import "./Transactions.scss";
import { Link } from "react-router-dom";
import { getNavLink } from "../../hooks/useCountryUrls";
import {
  HistoricTransactionsPageLocationState,
  HISTORIC_TRANSACTIONS_PAGE_URL,
} from "../historicTransactions/HistoricTransactionsPage";
import { InternalTransferEntry } from "./InternalTransferEntry";
import { TranslatedText } from "../../components/TranslatedText";

type CombinedTransfer = CashTransaction | InternalTransfer;
interface Props {
  account: InvestmentAccount | undefined;
  accounts: CompoundAccount[];
}

const MAX_TRANSACTIONS_SHOWN = 4;

export const Transactions: React.FC<Props> = ({ account, accounts }) => {
  const [combinedTransfers, setCombinedTransfers] = useState<
    CombinedTransfer[]
  >([]);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const timer = useRef<number | undefined>();

  const load = useCallback((accounts, account) => {
    const filter = { ...dataTransactions.getDefaultFilter() };
    filter.start = dataUser.getCreated(accounts);
    Promise.all([
      dataTransactions.getTransactions(filter),
      dataTransactions.getInternalTransfers(filter.start, filter.end),
    ])
      .then(([transactions, internalTransfers]) => {
        const filteredTransactions = transactions.filter(
          (item): item is CashTransaction =>
            instanceOfCashTransaction(item) &&
            item.accountId === account.accountId &&
            (item.type === CashTransactionType.DEPOSIT ||
              item.type === CashTransactionType.WITHDRAWAL)
        );
        const filteredInternalTransfers = internalTransfers.filter(
          (internalTransfer) =>
            internalTransfer.fromAccountId === account.accountId ||
            internalTransfer.toAccountId === account.accountId
        );

        const combinedTransfers = [
          ...filteredTransactions,
          ...filteredInternalTransfers,
        ];
        combinedTransfers.sort((a, b) => {
          return new Date(b.booked).getTime() - new Date(a.booked).getTime();
        });
        setCombinedTransfers(combinedTransfers);

        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    if (!account) {
      return;
    }
    load(accounts, account);
  }, [accounts, account, load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    timer.current = window.setTimeout(() => {
      load(accounts, account);
    }, 500);
  }, [accounts, account, load]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  let elem = null;
  let hasAdditionalTransactions =
    combinedTransfers.length > MAX_TRANSACTIONS_SHOWN;
  if (combinedTransfers.length === 0) {
    elem = (
      <Typography type="body">
        <TranslatedText id="accountPage.transactions.none" />
      </Typography>
    );
  } else {
    const items = combinedTransfers.slice(0, MAX_TRANSACTIONS_SHOWN);
    elem = (
      <div className="account-page-transactions-list">
        <ul>
          {items.map((item) => {
            if (instanceOfInternalTransfer(item)) {
              return (
                <li key={item.booked}>
                  <InternalTransferEntry
                    account={account}
                    internalTransfer={item}
                  />
                </li>
              );
            } else if (instanceOfCashTransaction(item)) {
              return (
                <li key={item.booked}>
                  <TransactionEntry transaction={item} />
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
        <div className="additional-transactions-indicator" />
      </div>
    );
  }

  return (
    <section
      className={cx("account-page-transactions", {
        "has-additional-transactions": hasAdditionalTransactions,
      })}
    >
      <Typography type="h2">
        <TranslatedText id="accountPage.transactions.header" />
      </Typography>

      <CardList>
        <Retry retry={retry} status={status}>
          <div className="account-page-padder">
            <div className="account-page-card-body">
              {hasAdditionalTransactions ? (
                <Typography type="label" component="span">
                  <TranslatedText
                    id="accountPage.transactions.recent"
                    values={{ name: account?.name }}
                  />
                </Typography>
              ) : (
                <Typography type="label" component="span">
                  <TranslatedText
                    id="accountPage.transactions.all"
                    values={{ name: account?.name }}
                  />
                </Typography>
              )}

              {elem}
            </div>
            <CardBottom>
              <Link<HistoricTransactionsPageLocationState>
                to={{
                  pathname: getNavLink(HISTORIC_TRANSACTIONS_PAGE_URL),
                  state: { accountId: account?.accountId },
                }}
              >
                <TranslatedText id="accountPage.transactions.link.text" />
              </Link>
            </CardBottom>
          </div>
        </Retry>
      </CardList>
    </section>
  );
};

import React, { useCallback, useRef, useState } from "react";
import { useLocation, useHistory, matchPath, Redirect } from "react-router-dom";
import { Story } from "@lysaab/ui-2";
import { getNavLink } from "../../hooks/useCountryUrls";
import { Done } from "./Done";
import { Warning } from "./Warning";
import { Location } from "history";
import { defineMessages, useIntl } from "react-intl";
import { useQuery } from "../../hooks/useQuery";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { HideNav } from "../HideNav";
import "./UpdateAccountPage.scss";
import { Route } from "../../components/route/Route";
import { Switch } from "../../components/route/Switch";
import {
  EditAccounrRiskParams,
  EditAccountRiskConfirm,
} from "../../pageComponents/editAccountRiskConfirm/EditAccountRiskConfirm";
import { dataInvestments } from "../../data/dataInvestments";
import { AllocationSelection } from "./AllocationSelection";
import { ACCOUNT_PAGE_URL } from "../account/AccountPage";

interface LocationStatePrevPath {
  prevPath?: string;
}

const messages = defineMessages({
  header: {
    id: "updateAccountPage.story.header",
    description: "Update account page - story header",
    defaultMessage: "Update accounts",
  },
  ariaProgressLabel: {
    id: "updateAccountPage.story.ariaProgressLabel",
  },
});

export const UPDATE_ACCOUNT_PAGE_URL = "/update-account";

export const ROUTES = {
  ALLOCATION_SELECTION: `${UPDATE_ACCOUNT_PAGE_URL}/allocation`,
  WARNING: `${UPDATE_ACCOUNT_PAGE_URL}/warning`,
  SUMMARY: `${UPDATE_ACCOUNT_PAGE_URL}/summary`,
  DONE: `${UPDATE_ACCOUNT_PAGE_URL}/done`,
};

const STORY_LENGTH = Object.values(ROUTES).length;

function hasMissingMandatoryParams(params: EditAccounrRiskParams) {
  if (
    !params.accountId ||
    !params.advisedRisk ||
    !params.takenRisk ||
    !params.investmentType ||
    !params.language ||
    !params.investmentType
  ) {
    return true;
  }

  return false;
}

function getExitRoute(
  location: Location<LocationStatePrevPath>,
  query: EditAccounrRiskParams,
  values?: EditAccounrRiskParams
) {
  let exitRoute = location?.state?.prevPath || getNavLink(ACCOUNT_PAGE_URL);

  if (query.accountId) {
    exitRoute = exitRoute.replace(":accountId", query.accountId);
  } else if (values && values.accountId) {
    exitRoute = exitRoute.replace(":accountId", values.accountId);
  } else {
    exitRoute = getNavLink(OVERVIEW_PAGE_URL);
  }

  return exitRoute;
}

function getStoryStartRoute(query: EditAccounrRiskParams) {
  return getNavLink(ROUTES.ALLOCATION_SELECTION);
}

function isRootPage(index: number) {
  return index < 0;
}

function getCurrentRouteIndex(location: Location<LocationStatePrevPath>) {
  return Object.values(ROUTES).findIndex((path) => {
    const match = matchPath(location.pathname, {
      path: getNavLink(path),
      exact: true,
    });
    return match !== null;
  });
}

export function UpdateAccountPage() {
  const location = useLocation<LocationStatePrevPath>();
  const history = useHistory();
  const intl = useIntl();
  const query = useQuery<EditAccounrRiskParams>();
  const values = useRef<EditAccounrRiskParams>();
  const currentIndex = getCurrentRouteIndex(location);
  const exitRoute = getExitRoute(location, query, values.current);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onClick = useCallback(() => {
    if (!values.current || typeof values.current.newRisk === "undefined") {
      return;
    }

    setHasError(false);
    setLoading(true);

    dataInvestments
      .updateRisk({
        accountId: values.current.accountId,
        takenRisk: parseInt(values.current.newRisk, 10),
      })
      .then(() => history.push(getNavLink(ROUTES.DONE)))
      .catch(() => setHasError(true))
      .finally(() => setLoading(false));
  }, [history]);

  if (isRootPage(currentIndex)) {
    if (hasMissingMandatoryParams(query)) {
      return <Redirect to={exitRoute} />;
    }

    values.current = { ...query };
    return <Redirect to={getStoryStartRoute(query)} />;
  }

  if (!values.current) {
    return <Redirect to={exitRoute} />;
  }

  return (
    <div className="update-account-page">
      <HideNav />
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: STORY_LENGTH,
          })
        }
        header={intl.formatMessage(messages.header)}
        onExit={() => history.push(exitRoute)}
        showClose={true}
        showBack={false}
        onBack={history.goBack}
        transitionKey={currentIndex.toString()}
        progress={(100 / Object.keys(ROUTES).length) * (currentIndex + 1)}
      >
        <Switch {...{ order: currentIndex }} location={location}>
          <Route path={getNavLink(ROUTES.ALLOCATION_SELECTION)}>
            <AllocationSelection values={values} />
          </Route>

          <Route path={getNavLink(ROUTES.WARNING)}>
            <Warning values={values} exitRoute={exitRoute} />
          </Route>

          <Route path={getNavLink(ROUTES.SUMMARY)}>
            <EditAccountRiskConfirm
              values={
                {
                  ...values.current,
                  takenRisk: values.current.newRisk,
                } as EditAccounrRiskParams
              }
              loading={loading}
              hasError={hasError}
              next={onClick}
            />
          </Route>

          <Route path={getNavLink(ROUTES.DONE)}>
            <Done values={values} exitRoute={exitRoute} />
          </Route>
        </Switch>
      </Story>
    </div>
  );
}

import {
  Card,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useEffect, useState, VoidFunctionComponent } from "react";
import {
  InvestmentAccount,
  AccountType,
  dataAccounts,
  isInvestmentAccount,
  SavingsAccount,
} from "../../../data/dataAccounts";
import { CloseAccountRow } from "./components/closeAccountRow/CloseAccountRow";
import { TranslatedText } from "../../../components/TranslatedText";
import { PendingDeposit } from "../../../components/deposits/pendingDeposits/PendingDepositCard";
import { usePendingDeposits } from "../../../hooks/usePendingDeposits";
import { useIsPerson } from "../../../hooks/useIsPerson";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";

import "./CloseAccount.scss";
import {
  dataTransfer,
  PendingInternalTransfer,
} from "../../../data/dataTransfer";

export const CloseAccount: VoidFunctionComponent = () => {
  const [accounts, setAccounts] = useState<
    (InvestmentAccount | SavingsAccount)[]
  >([]);
  const [pendingDeposits, setPendingDeposits] = useState<PendingDeposit[]>([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasOneVPAccount, setHasOneVPAccount] = useState(false);
  const loadPendingDeposits = usePendingDeposits();
  const isPerson = useIsPerson();
  const isReadonly = useIsReadOnly();
  const [numberOfInvestmentAccounts, setNumberOfInvestmentAccounts] =
    useState<number>(1);
  const [pendingInternalTransfers, setPendingInternalTransfers] = useState<
    PendingInternalTransfer[]
  >([]);

  useEffect(() => {
    if (typeof loadPendingDeposits === "undefined") {
      return;
    }
    Promise.all([
      dataAccounts.getAllAccounts(),
      loadPendingDeposits(),
      dataTransfer.getPendingInternalTransfers(),
    ])
      .then(([accounts, pendingDeposits, pendingInternalTransfers]) => {
        setNumberOfInvestmentAccounts(accounts.investmentAccounts.length);
        setAccounts([
          ...accounts.investmentAccounts,
          ...accounts.savingsAccounts,
        ]);
        setPendingDeposits(pendingDeposits);
        setPendingInternalTransfers(pendingInternalTransfers);
        setIsLoading(false);
        if (!isPerson) {
          const allVPAccounts = accounts.investmentAccounts.filter(
            (account) => account.type === AccountType.VP_SWE
          );
          setHasOneVPAccount(allVPAccounts.length === 1);
        }
      })
      .catch((error: ServerError<unknown>) => {
        setError(true);
        setIsLoading(false);
      });
  }, [isPerson, loadPendingDeposits]);

  if (error) {
    return (
      <section>
        <h2>
          <TranslatedText id="endInvestment.closeAccount.header" />
        </h2>
        <Card className="close-account-error">
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="endInvestment.closeAccount.error.noAccounts" />
          </Snackbar>
        </Card>
      </section>
    );
  }

  const isOnlyOneInvestmentAccount = numberOfInvestmentAccounts === 1;
  const accountsList = accounts.map((account) => {
    const isLastCorporateVPAccount =
      isInvestmentAccount(account) &&
      hasOneVPAccount &&
      account.type === AccountType.VP_SWE;

    const hasIncomingInternalTransfer = pendingInternalTransfers.some(
      (pendingInternalTransfer) =>
        pendingInternalTransfer.toAccountId === account.accountId
    );
    return (
      <CloseAccountRow
        key={account.accountId}
        account={account}
        isOnlyOneInvestmentAccount={isOnlyOneInvestmentAccount}
        pendingDeposits={pendingDeposits}
        isLastCorporateVPAccount={isLastCorporateVPAccount}
        hasIncomingInternalTransfer={hasIncomingInternalTransfer}
      />
    );
  });
  if (isLoading) return <Spinner />;
  return (
    <section>
      <h2>
        <TranslatedText id="endInvestment.closeAccount.header" />
      </h2>
      <Card className="close-account">
        {isReadonly ? (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="endInvestment.closeAccount.error.readOnly" />
          </Snackbar>
        ) : (
          accountsList
        )}
      </Card>
    </section>
  );
};

import React from "react";
import cx from "classnames";
import {
  CashTransactionType,
  CashTransaction,
} from "../../data/dataTransactions";
import { dataPerformance } from "../../data/dataPerformance";
import { Amount } from "../../components/amount/Amount";
import { FormattedMessage } from "react-intl";
import "./TransactionEntry.scss";
import { Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../components/TranslatedText";

interface Props {
  transaction: CashTransaction;
}

export const TransactionEntry: React.SFC<Props> = ({ transaction }) => {
  const isDeposit = transaction.type === CashTransactionType.DEPOSIT;
  const classes = cx(
    "transaction-entry",
    `transaction-entry-${transaction.type.toLowerCase()}`
  );

  return (
    <div className={classes}>
      <div>
        <div className="transaction-entry-header">
          <Typography type="body" variant="secondary" component="span">
            {dataPerformance.toStringDate(
              dataPerformance.toJsDate(transaction.booked)
            )}
          </Typography>
        </div>
        <div>
          <span className="transaction-entry-indicator" />{" "}
          {isDeposit ? (
            <FormattedMessage id="accountPage.transactions.deposit" />
          ) : (
            <FormattedMessage id="accountPage.transactions.withdrawal" />
          )}
        </div>
      </div>

      <div className="text-right">
        <div className="transaction-entry-header">
          <Typography type="body" variant="secondary" component="span">
            <TranslatedText id="accountPage.transactions.amount" />
          </Typography>
        </div>
        <div className="transaction-entry-type">
          {isDeposit ? (
            <Amount amount={transaction.amount} decimals={2} />
          ) : (
            <Amount amount={transaction.amount * -1} decimals={2} />
          )}
        </div>
      </div>
    </div>
  );
};

import { LysaCountry, WorldCountry } from "@lysaab/countries";
import { API, cache } from "@lysaab/ui-2";
import { Language } from "../context/LocalizationContext";
import { LegalEntityType } from "./dataLogin";

export interface LegalEntityResponse {
  name: string;
  language: Language;
  country: LysaCountry;
  legalEntityType: LegalEntityType;
  lastLogin: string;
  birthday: string;
  created: string;
  tin: string;
  email: string;
}

export interface EntityDetailsResponse {
  entityType: LegalEntityType;
  contactDetails: EntityContactDetails;
  details: EntityDetails;
}

export interface EntityContactDetails {
  email: string;
  contactNumber?: string;
  commercialEmail: boolean;
  notificationEmail: boolean;
  emailVerified?: string;
}

export interface EntityDetails {
  fullName: string;
  tin: string;
  citizenships: string[];
  citizenshipsIso: WorldCountry[];
  taxResidence: string;
  taxResidenceIso: string;
  language: Language;
  birthday: string;
}

export interface UpdateRequest {
  email?: string;
  contactNumber?: string;
  commercialEmail?: boolean;
  notificationEmail?: boolean;
  birthday?: string;
  language?: Language;
}

export interface InitEmailChangeRequest {
  email: string;
  country: LysaCountry;
  language: Language;
}

export interface VerifyEmailResponse {
  confirmationId: string;
  expire: string;
  statusId: string; // "EM9kTQQ8T-p4CvyZ2OADfW0="
}

export interface ConfirmEmailCodeRequest {
  code: string;
  confirmationId: string;
}

export enum EmailVerificationCodeResult {
  OK = "OK",
  EXPIRED = "EXPIRED",
  NOT_VALID = "NOT_VALID",
}

export interface ConfirmEmailCodeResponse {
  result: EmailVerificationCodeResult;
  signedEmail: string;
}

export interface FinalizeEmailChangeRequest {
  signedEmail: string;
}

export const dataLegalEntity = {
  getLegalEntity: () => {
    return API.get<LegalEntityResponse>("/legal-entity", true);
  },
  getProfileSettings: () => {
    return API.get<EntityDetailsResponse>("/legal-entity/profile/settings");
  },
  postProfileSettings: (settingsUpdateRequest: UpdateRequest) => {
    return API.post<EntityDetailsResponse>(
      "/legal-entity/profile/settings",
      settingsUpdateRequest
    ).then((response) => {
      cache.delete("/legal-entity/profile/settings");
      cache.delete("/user-state");
      return response;
    });
  },
  initEmailChange: (requestData: InitEmailChangeRequest) => {
    return API.post<VerifyEmailResponse>(
      "/legal-entity/email/verify",
      requestData
    );
  },
  confirmEmailCode: (requestData: ConfirmEmailCodeRequest) => {
    return API.post<ConfirmEmailCodeResponse>(
      "/legal-entity/email/confirm",
      requestData
    );
  },
  finalizeEmailChange: (requestData: FinalizeEmailChangeRequest) => {
    return API.post<{}>("/legal-entity/email/change", requestData).then(() => {
      cache.delete("/legal-entity/profile/settings");
    });
  },
  updateCitizenships: (citizenships: WorldCountry[]) => {
    return API.post<EntityDetailsResponse>("/legal-entity/citizenships", {
      citizenships,
    }).then(() => {
      cache.delete("/legal-entity/profile/settings");
    });
  },
};

import React, { useReducer, createContext, FunctionComponent } from "react";
import {
  ExternalAccount,
  WithdrawableSavingsAccount,
  WithdrawalAccount,
} from "../../../data/dataWithdrawals";

import { InvestmentAccount, SavingsAccount } from "../../../data/dataAccounts";

export type MergedInvestmentAccount = InvestmentAccount & WithdrawalAccount;
export type MergedSavingsAccount = SavingsAccount & WithdrawableSavingsAccount;

export type MergedWithdrawalAccount =
  | MergedInvestmentAccount
  | MergedSavingsAccount;

export interface WithdrawalState {
  selectedLysaAccount?: MergedInvestmentAccount;
  selectedLysaSavingsAccount?: MergedSavingsAccount;
  selectedExternalAccount?: ExternalAccount;
  amount?: string;
  drain?: boolean;
}

export interface WithdrawalContextProps {
  state: WithdrawalState;
  setState: (newState: Partial<WithdrawalState>) => void;
}

export const WithdrawalContext = createContext<WithdrawalContextProps>(
  {} as WithdrawalContextProps
);

function stateReducer(
  state: WithdrawalState,
  newState: Partial<WithdrawalState>
) {
  return { ...state, ...newState };
}

export const WithdrawalContextProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <WithdrawalContext.Provider value={{ state, setState }}>
      {children}
    </WithdrawalContext.Provider>
  );
};

export function useWithdrawalContext() {
  const context = React.useContext(WithdrawalContext);
  if (context === undefined) {
    throw new Error(
      "useWithdrawalContext must be used within a WithdrawalContextProvider"
    );
  }
  return context;
}
